import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from "@angular/core";
import { subDays } from "date-fns";
import { ExportOption, ExportRequestEvent } from "src/app/core/app-services/export.service";
import { DateRange } from "src/app/core/plots/plot.models";

@Component({
    selector: "app-export",
    template: `
        <div 
            *ngIf="isOpen"
            class="export-popup"
            [class.has-date-picker]="showDatePicker"
            [@fadeInAnimation]
        >
            <div 
                *ngIf="showDatePicker"
                class="p-16 pb-0"
            >
                <app-date-range
                    format="vertical"
                    size="small"
                    [dateRange]="dateRange"
                    (dateRangeChange)="this.dateRange = $event"
                >
                </app-date-range>
            </div>
            <div *ngIf="exportOptions!.length > 1">
                <div 
                    *ngFor="let option of exportOptions"
                    class="radio-cont"
                    [class.disabled]="polling"
                >
                    <div
                        class="radio-select"
                        [tooltip]="option.tooltip ? option.tooltip : null"
                        size="small"
                        textAlign="left"
                    >
                        <input 
                            type="radio"
                            class="flex-shrink-0"
                            [id]="option.value"
                            [checked]="selectedValue === option.value"
                            [disabled]="option.disabled ? true : null"
                            [value]="option.value"
                            (change)="updateSelection($event)"
                        >
                        <label
                            [for]="option.value"
                            [class.disabled]="option.disabled ? true : null"
                        >{{ option.label }}</label>
                    </div>
                </div>
            </div>
            <div 
                *ngIf="exportOptions!.length === 1"
                class="single-option-box"
            >
                {{ 'EXPORT.EXPORT_CONTENT' | translate: {content: exportOptions![0].label} }}
            </div>
            <div class="button-wrapper">
                <button 
                    class="default-button"
                    (click)="emitExport()"
                    [class.disabled]="buttonDisabled || polling"
                >
                    <span *ngIf="!polling">
                        {{ 'EXPORT.DEFAULT' | translate }}
                    </span>
                    <app-preloader 
                        *ngIf="polling" 
                        color="white" 
                        type="squares"
                    ></app-preloader>
                </button>
            </div>
        </div>
    `,
    styleUrls: ["./export.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(-10px)', opacity: 0 }),
                animate('.25s ease-out', 
                    style({ transform: 'translateY(0px)', opacity: 1 })
                )
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0px)', opacity: 1 }),
                animate('.25s ease-in', 
                    style({ transform: 'translateY(-10px)', opacity: 0 })
                )
            ])
        ])
    ],
    host: {
        '[@fadeInAnimation]': 'true'
    }
})
export class ExportComponent implements OnInit, OnChanges {
    // options to display
    @Input() exportOptions: ExportOption[] | null = [];
    // whether or not the export window should be shown
    @Input() isOpen: boolean | null = null;
    // show polling state
    @Input() polling: boolean | null = false;
    // show dateRange picker, enables output of onExport event instead of instantly exporting
    @Input() showDatePicker: boolean | null = false;
    // emits selected option value on export btn click, additionally emits selected dateRange
    @Output() onExportRequest = new EventEmitter<ExportRequestEvent>();
    // two way binding is open output
    @Output() isOpenChange = new EventEmitter<boolean>();

    buttonDisabled: boolean = false;

    // helpers for UI
    private _unlistenEvents: (() => void)[] = [];

    public dateRange: DateRange = {from: subDays(new Date(), 14), until: new Date()};
    public selectedValue: string | null = null;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _elRef: ElementRef,
        private _renderer: Renderer2
    ) {}

    ngOnInit() {
        this.initSelection();
    }

    ngOnChanges(changes: SimpleChanges) {
        const isOpenChange = changes['isOpen'];
        if (isOpenChange) {
            isOpenChange.currentValue ? this.openPopup() : this.hidePopup();
            this.initSelection();
        }
    }

    initSelection() {
        if (this.exportOptions && this.exportOptions.length > 0) {
            this.selectedValue = this.exportOptions[0].value
        }
    }

    // set selected value from radio buttons
    updateSelection(event: any) {
        let value = event.target?.value;
        if (!value || value.length == 0) return;
        this.selectedValue = value
        this.buttonDisabled = false
    }

    hidePopup(): void {
        if (this.polling) return
        this.isOpen = false;
        this.isOpenChange.emit(false);
        this._removeListeners()
        this._changeDetectorRef.detectChanges()
    }

    openPopup(): void {
        const disabledOptions = this.exportOptions!.filter(x => x.disabled);
        if (this.selectedValue && disabledOptions.length > 0) {
            const disabledOptionsValues = disabledOptions.map(x => x.value)
            // if currently selected values becomes disabled, disable export button
            this.buttonDisabled = (disabledOptionsValues.indexOf(this.selectedValue) > -1)
        }

        this.isOpen = true;
        this.isOpenChange.emit(true);
        this._setListeners()
        // manually detect changes for date picker
        setTimeout(() => {
            this._changeDetectorRef.detectChanges()
        }, 100)
    }

    private _setListeners() {
        this._unlistenEvents.push(
            this._renderer.listen('document', 'mousedown', (event) => {
                // prevent closing button while selecting date
                const datePickerOverlay = document.querySelector('date-range-popup');
                const onDatePicker = datePickerOverlay && datePickerOverlay.contains(event.target);
                const triggerClasses = ['btn-export', 'subnav-export', 'overview-exp-icon'];
                if (
                    !this._elRef.nativeElement.contains(event.target) && 
                    !triggerClasses.some(className => event.target.classList.contains(className)) && 
                    this.isOpen && 
                    !onDatePicker
                ) this.hidePopup();
            }),
            this._renderer.listen('document', 'keydown.esc', () => this.hidePopup())
        )
    }

    private _removeListeners() {
        this._unlistenEvents.forEach((fn) => fn())
    }

    emitExport() {
        if (this.selectedValue == null) return
        
        this.onExportRequest.emit({
            value: this.selectedValue,
            dateRange: this.dateRange
        })
    }
}
